import React, { Component } from "react";
import { Link } from "gatsby";
import { HashLink } from 'react-router-hash-link';
import scrollTo from 'gatsby-plugin-smoothscroll';

import SimpleSearchModal from '../../utilities/SimpleSearchModal';
import SimpleModal from '../../utilities/SimpleModal';

import './index.scss';

import logo from '../../../images/merit-mile-logo.svg';
import search from '../../../images/icon-search.svg';

class Box extends Component {

    constructor(props) {
		super(props);
		// this.props.loadMenu(api.Menus.bySlug('main'));
		this.buildMenu = this.buildMenu.bind(this);
        this.state = {
            active: false,
			activeAbout: false,
			activeWork: false,
			activeContact: false,
        };
        this.toggleClass= this.toggleClass.bind(this);
        this.toggleAboutClass= this.toggleAboutClass.bind(this);
        this.toggleWorkClass= this.toggleWorkClass.bind(this);
        this.toggleContactClass= this.toggleContactClass.bind(this);
        this.removeMenu = this.removeMenu.bind(this);

        this.modalRef = React.createRef();
        this.openModal = this.openModal.bind(this);    
        this.modalSearchRef = React.createRef();    
        this.openModalSearch = this.openModalSearch.bind(this);
	}

    openModal() {
        this.modalRef.current.handleOpen();
    };

    openModalSearch() {
        this.modalSearchRef.current.handleOpen();
    };

	buildMenu() {
		if (this.props.mainMenu) {
			return this.props.mainMenu.map((item, i) => {
				return (
					<HashLink key={item.ID} to={item.url}>{item.title}</HashLink>
				);
			})
		}

		return null;
    }

	scrollToForm() {
        const containerElem = document.getElementById("box");
        setTimeout(() => {
            this.animateScroll(containerElem);
        }, 500);
    };

    animateScroll(container) {
        container.scrollTo({
            top:10000,
            behavior: "smooth"
        });
    };

    toggleClass() {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    };

	toggleAboutClass() {
		const currentState = this.state.activeAbout;
		this.setState({ activeAbout: !currentState });
	}

    toggleWorkClass() {
        const currentState = this.state.activeWork;
        this.setState({ activeWork: !currentState });
    }

    toggleContactClass() {
        const currentState = this.state.activeContact;
        this.setState({ activeContact: !currentState });
        this.scrollToForm();
    }

    scrollWithOffset = (el, offset) => {
        const elementPosition = el.offsetTop - offset;
        window.scroll({
            top: elementPosition,
            left: 0,
            behavior: "smooth"
        });
    };

    removeMenu() {
        this.setState({ active: false });
    }

    handleClickScroll(sectionElement) {
        scrollTo(sectionElement);
        this.setState({ active: false });
    }

    render() {
        return (
            <header className="header-main" id="header">
                <nav className="navbar navbar-light bg-white">
                    <Link className="navbar-brand mr-auto" to="/"><img src={logo} className="img-fluid" width="410" height="62" alt="Merit Mile, LLC" /></Link>

                    <div className="search-link" onClick={(e) => {this.openModalSearch();}}><img src={search} width="57" height="51" alt="Search..." /></div>
                    <SimpleSearchModal innerRef={this.modalSearchRef}/>
                    <Link id="btnLink"
                        to="/contact-us/" >
                        Get In Touch
                    </Link>
                    <div id="btn"
                            className={this.state.active ? 'active': null}
                            onClick={this.toggleClass} >
                        <div id='top'></div>
                        <div id='middle'></div>
                        <div id='middle'></div>
                        <div id='bottom'></div>
                    </div>
                    <div id="box"
                            className={this.state.active ? 'active': null}>
                        <div id="items">
                            <ul>
                                <li>
                                <Link
                                    to="/#about" 
                                    onClick={(e) => this.handleClickScroll('#about')}
                                    className='item'>About</Link>
                                </li>
                                <li>
                                <Link
                                    to="/case-studies/"
                                    onClick={(e) => this.removeMenu()}
                                    className='item'>Case Studies</Link>
                                </li>
                                <li>
                                <Link
                                    to="/services/" 
                                    onClick={(e) => this.removeMenu()}
                                    // onClick={(e) => this.handleClickScroll('#services')}
                                    className='item'>Services</Link>
                                </li>
                                <li>
                                <Link
                                    to="/portfolio/"
                                    onClick={(e) => this.removeMenu()}
                                    className='item'>Our Work</Link>
                                </li>
                                <li>
                                <div className='item' onClick={(e) => {
                                    this.openModal();
                                    this.removeMenu();
                                }}>Reel</div>
                                </li>
                                <li>
                                <SimpleModal videoSRC='f7FaGLdtgt0' innerRef={this.modalRef}/>
                                <Link
                                    to="/#clients"
                                    onClick={(e) => this.handleClickScroll('#clients')}
                                    className='item'>Clients</Link>
                                </li>
                                <li>
                                <Link
                                    to="/news/"
                                    onClick={(e) => this.removeMenu()}
                                    className='item'>News</Link>
                                </li>
                                <li>
                                <Link
                                    to="/contact-us/"
                                    onClick={(e) => this.removeMenu()}
                                    className='item'>Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                </nav>
            </header>
        )
    }    
}

export default ({pageContext}) => (
    <Box />
)


